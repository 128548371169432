import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{staticClass:"ma-3",attrs:{"wrap":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{directives:[{name:"validate",rawName:"v-validate",value:('required|min:3|max:150'),expression:"'required|min:3|max:150'"}],ref:"descricao",attrs:{"label":"Descrição","placeholder":"Digite a descrição da área de atuação","dense":"","outlined":"","data-vv-as":"descrição","persistent-hint":"","hint":_vm.errors.first('res_beneficio.descricao'),"error":_vm.errors.collect('res_beneficio.descricao').length ? true : false,"data-vv-scope":"res_beneficio","data-vv-validate-on":"change","id":"descricao","name":"descricao"},model:{value:(_vm.beneficio.descricao),callback:function ($$v) {_vm.$set(_vm.beneficio, "descricao", $$v)},expression:"beneficio.descricao"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"},{name:"mask",rawName:"v-mask",value:([
          '###,##',
          '#.###,##',
          '##.###,##',
          '###.###,##',
          '#.###.###,##' ]),expression:"[\n          '###,##',\n          '#.###,##',\n          '##.###,##',\n          '###.###,##',\n          '#.###.###,##',\n        ]"}],ref:"valor",attrs:{"label":"Valor","placeholder":"Digite o valor do benefício","dense":"","outlined":"","data-vv-as":"valor","persistent-hint":"","hint":_vm.errors.first('res_beneficio.valor'),"error":_vm.errors.collect('res_beneficio.valor').length ? true : false,"data-vv-scope":"res_beneficio","data-vv-validate-on":"change","id":"valor","name":"valor"},model:{value:(_vm.beneficio.valor),callback:function ($$v) {_vm.$set(_vm.beneficio, "valor", $$v)},expression:"beneficio.valor"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }