<template>
  <section name="beneficios">
    <v-row>
      <v-col cols="12">
        <Crud
          title="Gestão de Benefícios"
          buttonAddItemText="Novo benefício"
          searchLabelText="Pesquisar na lista"
          :searchTextField.sync="searchTextField"
          :formTitle="nameForm"
          :callBackModalCancelar="fechar"
          :dialog.sync="dialog"
          :callBackModalSalvar="salvar"
          :dialogDelete.sync="dialogDelete"
        >
          <template slot="data-content">
            <v-row class="mt-5">
              <!-- FILTRO CAMPOS -->
            </v-row>
          </template>
          <template slot="data-table">
            <v-data-table
              :headers="
                headers.filter((header) => {
                  return header.value != 'editar' && header.value != 'acao';
                })
              "
              :items="beneficios"
              class="elevation-5"
              :loading="loadingTable"
            >
              <!-- ACTIONS -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <!-- Visualizar -->
                    <v-list-item>
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon
                                class="actions"
                                @click="toggleModalLateral(item)"
                              >
                                mdi-eye
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Visualizar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>

                    <!-- Editar -->
                    <v-list-item>
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon class="actions" @click="editar(item)">
                                mdi-pencil
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Editar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>

                    <!-- Deletar -->
                    <v-list-item>
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon
                                class="actions"
                                @click="remover(item, true)"
                              >
                                mdi-delete
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Deletar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-switch
                  class="mt-0 pt-0"
                  v-model="item.status"
                  @click="mudarStatus(item)"
                  :label="item.status == 'A' ? 'Ativo' : 'Inativo'"
                  true-value="A"
                  false-value="I"
                  color="success"
                  hide-details
                ></v-switch>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                <span
                  >{{ item.created_at | dateFormatBr() }}
                  {{ item.created_at | timeFormatBr() }}h</span
                >
              </template>
              <template v-slot:[`item.valor`]="{ item }">
                <span>{{ item.valor | numberFormatBrWithoutCurrency() }}</span>
              </template>
            </v-data-table>
          </template>
          <!--------------- MODAL DE DELETE --------------------------------------------- -->
          <template slot="modalDelete/titleModal">
            Excluir o registro?
          </template>
          <template slot="modalDelete/contentModal">
            <p>
              Atenção! Você está prestes a realizar uma ação que não pode ser
              desfeita.
            </p>
            <p>Você realmente deseja excluir esse registro?</p>
          </template>
          <template slot="modalDelete/actionsModal">
            <v-btn depressed @click="dialogDelete = false">Fechar</v-btn>
            <v-btn
              :loading="loadingButtonDelete"
              color="error"
              @click="remover()"
            >
              Excluir
            </v-btn>
            <v-spacer class="d-none d-md-block"></v-spacer>
          </template>
          <!-- ------------- MODAL DE CADASTRO --------------------------------------------- -->
          <template slot="contentModalCrud">
            <BeneficiosForm />
          </template>
        </Crud>
      </v-col>
    </v-row>

    <ModalLateral
      :show="modalLateral.show"
      :hide="toggleModalLateral"
      :title="modalLateral.title"
      :headers="headerModalLateral"
      :item="modalLateral.item"
    >
      <template v-slot:[`item.status`]="{ item }">
        <v-chip :color="item.status == 'A' ? 'success' : 'secondary'">{{
          item.status == "A" ? "Ativo" : "Inativo"
        }}</v-chip>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        <span
          >{{ item.created_at | dateFormatBr() }}
          {{ item.created_at | timeFormatBr() }}h</span
        >
      </template>
      <template v-slot:[`item.updated_at`]="{ item }">
        <span
          >{{ item.updated_at | dateFormatBr() }}
          {{ item.updated_at | timeFormatBr() }}h</span
        >
      </template>
      <template v-slot:[`item.valor`]="{ item }">
        <span>
          {{ formatNumberToMoneyBRL(item.valor) }}
        </span>
      </template>
    </ModalLateral>
  </section>
</template>

<script>
import Crud from "@/components/Crud.vue";
import ModalLateral from "@/components/ModalLateral.vue";
import BeneficiosForm from "./forms/BeneficiosForm.vue";
import { parseLocaleNumber } from "@/helpers";

export default {
  name: "BeneficiosTab",
  inject: ["$validator"],
  components: {
    Crud,
    ModalLateral,
    BeneficiosForm,
  },
  data() {
    return {
      nameForm: "Novo benefício",
      modalLateral: {
        show: false,
        item: [],
        title: "",
      },
      searchTextField: "",
      dialog: false,
      loadingTable: false,
      headers: [
        { text: "Descrição", value: "descricao" },
        { text: "Valor", value: "valor" },
        { text: "Status", value: "status" },
        { text: "Data de criação", value: "created_at" },
        { text: "Ações", value: "actions" },
      ],
      headerModalLateral: [
        { text: "Descrição", value: "descricao" },
        { text: "Valor", value: "valor" },
        { text: "Status", value: "status" },
        { text: "Data de criação", value: "created_at" },
        { text: "Data de atualização", value: "updated_at" },
      ],

      dialogDelete: false,
      loadingButtonDelete: false,
      item: [],
    };
  },

  created() {
    this.loadingTable = true;

    this.$store.dispatch("resVagasBeneficios/initialize").finally(() => {
      this.loadingTable = false;
    });
  },

  computed: {
    beneficios: {
      get() {
        return this.$store.getters["resVagasBeneficios/getVagasBeneficios"];
      },
    },
    beneficio: {
      get() {
        return this.$store.getters["resVagasBeneficios/getVagaBeneficio"];
      },
      set(value) {
        this.$store.dispatch("resVagasBeneficios/setVagaBeneficio", value);
      },
    },
  },

  methods: {
    salvar() {
      this.$validator.validate("res_beneficio.*").then((result) => {
        if (result) {
          const isEdit = this.beneficio.id || this.beneficio.uuid ? 1 : -1;

          this.dialog = false;
          this.loadingTable = true;
          let url =
            isEdit === -1
              ? "/res-vaga-beneficio"
              : "/res-vaga-beneficio/" + this.beneficio.uuid;
          let method = isEdit === -1 ? "POST" : "PUT";
          let sucesso =
            isEdit === -1
              ? "Benefício cadastrado com sucesso."
              : "Benefício atualizado com sucesso.";
          let erro =
            isEdit === -1
              ? "Erro ao salvar o benefício."
              : "Erro ao atualizar o benefício.";

          let beneficioItem = this.beneficio;
          beneficioItem._method = method;
          beneficioItem.valor = parseLocaleNumber(
            this.beneficio.valor,
            "pt-br"
          );
          beneficioItem.url = url;

          this.$store
            .dispatch("resVagasBeneficios/save", beneficioItem)
            .then((response) => {
              if (response != "NAO_AUTORIZADO") {
                if (isEdit === -1) {
                  this.beneficios.push(response);
                } else {
                  const index = this.beneficios.findIndex(
                    (el) => el.id == beneficioItem.id
                  );

                  Object.assign(this.beneficios[index], beneficioItem);
                }

                this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                  visibilidade: true,
                  mensagem: sucesso,
                  cor: "green",
                });
              }
            })
            .catch((error) => {
              console.log(error);
              this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                visibilidade: true,
                mensagem: erro,
                cor: "red",
              });
            })
            .finally(() => {
              this.fechar();
            });
        }
      });
    },
    editar(item) {
      this.loadingTable = true;

      this.$store
        .dispatch("resVagasBeneficios/findByUuid", item.uuid)
        .then((response) => {
          this.beneficio = response;
          this.nameForm = "Editar benefício: " + this.beneficio.descricao;
          this.dialog = true;
        })
        .catch(() => {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem:
              "Houve um erro ao buscar todos os dados do benefício selecionado.",
            cor: "red",
          });
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },
    fechar() {
      this.dialog = false;
      this.loadingTable = false;
      this.nameForm = "Novo benefício";
      this.beneficio = {
        descricao: "",
      };
    },
    remover(item, openModal = false) {
      if (openModal) {
        this.item = Object.assign({}, item);
        this.dialogDelete = true;
      } else {
        this.loadingButtonDelete = true;
        const indexItem = this.beneficios.findIndex(
          (el) => el.id == this.item.id
        );

        this.$store
          .dispatch("resVagasBeneficios/remove", this.item.uuid)
          .then((response) => {
            if (response) {
              this.beneficios.splice(indexItem, 1);

              this.$store.dispatch("snackBarModule/openSnackBar", {
                color: "success",
                timeout: 3000,
                text: "Benefício removido.",
              });
              this.item = {};
            }
          })
          .catch(() => {
            this.$store.dispatch("snackBarModule/openSnackBar", {
              color: "error",
              timeout: 3000,
              text: "Não foi possível remover o benefício.",
            });
          })
          .finally(() => {
            this.loadingButtonDelete = false;
            this.dialogDelete = false;
          });
      }
    },
    mudarStatus(item) {
      this.loadingTable = true;

      let setorItem = {
        status: item.status,
        _method: "PUT",
        url: "/res-vaga-beneficio/" + item.uuid,
      };

      this.$store
        .dispatch("resVagasBeneficios/save", setorItem)
        .then((response) => {
          if (response != "NAO_AUTORIZADO") {
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              mensagem: "Benefício atualizado com sucesso.",
              cor: "green",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem: "Erro ao atualizar benefício.",
            cor: "red",
          });
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },
    //Modal Lateral
    toggleModalLateral(item) {
      if (this.modalLateral.show == false) {
        this.loadingTable = true;

        this.$store
          .dispatch("resVagasBeneficios/findByUuid", item.uuid)
          .then((response) => {
            this.modalLateral.title = response.descricao;
            this.modalLateral.item = response ? response : [];
            this.modalLateral.show = true;
          })
          .catch(() => {
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              mensagem:
                "Houve um erro ao buscar todos os dados do benefício selecionado.",
              cor: "red",
            });
          })
          .finally(() => {
            this.loadingTable = false;
          });
      } else {
        this.modalLateral.show = false;
      }
    },
    /**
     * @description Recebe um numero e transforma o mesmo em formato de dinheiro.
     *
     * @param number
     * @param locale
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    formatNumberToMoneyBRL(number, locale = "pt-BR") {
      return new Intl.NumberFormat(locale, {
        style: "currency",
        currency: "BRL",
      })
        .format(number)
        .replace("R$", "");
    },
  },
};
</script>

<style scoped></style>
